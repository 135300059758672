import React from 'react';

import { getFormattedDate } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

import { GetBiReportOrgOptionsQuery } from '@typings/operations';

interface CustomLabelProps {
  data: GetBiReportOrgOptionsQuery['options'][number];
}
export const CustomLabel: React.FC<CustomLabelProps> = ({ data }) => {
  const { t } = useTranslation();

  const startDateText = t('data-selector.verify-continue-start', 'Start');
  const formattedStartDate = getFormattedDate(
    data.termStartDate,
    'DD MMMM YYYY'
  );
  const startDateLabel = `${startDateText}: ${formattedStartDate}`;

  const endDateText = data.termEndDate
    ? t('data-selector.verify-continue-end', 'End')
    : '';
  const formattedEndDate = data.termEndDate
    ? getFormattedDate(data.termEndDate, 'DD MMMM YYYY')
    : '';
  const endDateLabel =
    endDateText && formattedEndDate
      ? `${endDateText}: ${formattedEndDate}`
      : '';

  return (
    <div className="flex flex-row w-full gap-17 text-xs">
      <div className="basis-1/3 m-0">
        <div className="font-bold m-0 text-gray-700">
          {getClubRoleTValue(t, data.roleName)}
        </div>
        <div>{data.clubName}</div>
      </div>
      <div className=" basis-2/3">
        {data.matchType === 'region' ? (
          <div> {data.regionalGroupName} </div>
        ) : (
          <div>
            {t('data-selector.verify-continue-district', 'District')}{' '}
            {data.districtId}
          </div>
        )}
        <div>{`${startDateLabel} ${endDateLabel}`}</div>
      </div>
    </div>
  );
};
